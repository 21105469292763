import styles from '@/components/template/footer/FooterAddress/FooterAddress.module.scss'

const FooterAddress = () => {
  return (
    <div className={styles.root}>
      <p className="mb-4">
        <span className={styles.title}>SURPRISE KULTOUR AG</span>
        Rudolfstrasse 37
        <br />
        8400 Winterthur
      </p>
      <p className="mb-4">
        <span className={styles.country}>CH</span>
        <a href="tel:0041522351000">+41 (0)52 235 10 00</a>
        <br />

        <span className={styles.country}>D</span>
        <a href="tel:004977329505135">+49 (0)7732 950 51 35</a>
        <br />

        <a href="mailto:info@kultour.ch">info@kultour.ch</a>
      </p>
    </div>
  )
}

export default FooterAddress
